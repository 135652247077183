import axiosInstance from "client/services/Backend/axiosInstance";
import { io, Socket } from "socket.io-client";
import { DefaultEventsMap } from "socket.io/dist/typed-events";

let socket: Socket | null = null;
let queue: ((socket: Socket) => void)[] = [];
let isConnecting = false;

const socketUrls: Record<string, string> = {
    LIVE: "https://notification.freeconvert.com/",
    SANDBOX: "https://dev-notification.freeconvert.com/",
    PREPROD: "https://preprod-notification.freeconvert.com/",
};
const tokenUrls: Record<string, string> = {
    LIVE: "https://api.freeconvert.com/v1/account/guest",
    SANDBOX: "https://dev-api.freeconvert.com/v1/account/guest",
    PREPROD: "https://preprod-api.freeconvert.com/v1/account/guest",
};

const FREE_CONVERT_ENV = process.env.NEXT_PUBLIC_FREE_CONVERT_ENV ?? "";

export const getSocket = async (): Promise<Socket<DefaultEventsMap, DefaultEventsMap>> => {
    if (socket) return socket;
    if (isConnecting) {
        return new Promise(resolve => {
            queue.push(resolve);
        });
    }
    const token = await getFCToken();
    socket = io(socketUrls[FREE_CONVERT_ENV], {
        transports: ["websocket"],
        auth: {
            token: `Bearer ${token}`,
        },
    });
    queue.forEach(resolve => socket && resolve(socket));
    queue = [];
    isConnecting = false;
    return socket;
};

let token = "";
async function getFCToken(): Promise<string> {
    if (token) return token;
    token = (await axiosInstance.get<string>(tokenUrls[FREE_CONVERT_ENV])).data;
    return token;
}

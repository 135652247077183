type Event = "event";

export const gtag = (event: Event, eventName: string, data: Record<string, string>) => {
    if (!(<any>window).gtag) {
        const interval = setInterval(() => {
            if ((<any>window).gtag) {
                (<any>window).gtag(event.toString(), eventName, data);
                clearInterval(interval);
            }
        }, 50);
    } else {
        (<any>window).gtag(event.toString(), eventName, data);
    }
};

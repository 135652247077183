import type { Task } from "@freeconvert/freeconvert-node/dist/types";
import { Socket } from "socket.io-client";
import { DefaultEventsMap } from "socket.io/dist/typed-events";
import { getSocket } from "client/utils/getSocket";
import { get } from "../Backend/get";

let lastDisconnectTime: Date | null = null;
let offlineLimitWatcherInterval: NodeJS.Timer | null = null;
const OFFLINE_LIMIT = 60 * 1000 * 10; // 10 minutes

export interface TaskSocketOptions {
    handleTaskFail: (task: Task) => void;
    handleTaskComplete: (task: Task) => void;
    handleTaskStart?: (task: Task) => void;
    handleReconnect?: () => void;
    handleDisconnect?: () => void;
    onOfflineLimitReach?: (task: Task) => void;
}

interface SocketTask {
    task: Task;
    options: TaskSocketOptions;
}

let tasks: SocketTask[] = [];
let socket: Socket<DefaultEventsMap, DefaultEventsMap> | null = null;

export class TaskSocket {
    constructor() {
        this.setOfflineLimitWatcher();
        this.addInternetEventListeners();
    }

    public async appendTask(task: Task, options: TaskSocketOptions) {
        const socket = await this.getSocket();
        tasks.push({ task, options });
        this.subscribe(task, socket);
        this.addCypressEventListener(task, options);
    }

    private async getSocket(): Promise<Socket<DefaultEventsMap, DefaultEventsMap>> {
        if (socket) return socket;

        socket = await getSocket();

        socket?.on("task_failed", (data: Task) => {
            this.handleTaskFailed(data);
        });

        socket?.on("task_started", (data: Task) => {
            this.handleTaskStart(data);
        });

        socket?.on("task_completed", (data: Task) => {
            this.handleTaskComplete(data);
        });

        socket.on("disconnect", this.handleDisconnect);

        socket.on("reconnect", this.handleReconnect);

        return socket;
    }

    private subscribe(task: Task, socket: Socket<DefaultEventsMap, DefaultEventsMap>) {
        socket.emit("subscribe", `task.${task.id}`);
    }

    private handleTaskComplete = (task: Task) => {
        const foundTask = tasks.find((_task) => task.id === _task.task.id);

        if (!foundTask) {
            return;
        }

        foundTask.options.handleTaskComplete(task);
        tasks = tasks.filter((_task) => _task.task.id !== task.id);
    };

    private handleTaskStart = (task: Task) => {
        const foundTask = tasks.find((_task) => task.id === _task.task.id);

        if (!foundTask) {
            return;
        }

        foundTask.options.handleTaskStart?.(task);
    };

    private handleTaskFailed = (task: Task) => {
        tasks.forEach((_task) => {
            if (_task.task.id !== task.id) return;

            _task.options.handleTaskFail(task);
        });
    };

    private addInternetEventListeners() {
        window.addEventListener("online", this.handleReconnect);
        window.addEventListener("offline", this.handleDisconnect);
    }

    private addCypressEventListener(task: Task, options: TaskSocketOptions) {
        // @ts-ignore
        if (window.Cypress) {
            document.body.addEventListener(`task_failed.${task.id}`, (data: any) => {
                options.handleTaskFail(data.task as Task);
            });
        }
    }

    private setOfflineLimitWatcher() {
        if (offlineLimitWatcherInterval) return;

        offlineLimitWatcherInterval = setInterval(() => {
            if (!lastDisconnectTime) return;

            if (new Date().getTime() - lastDisconnectTime.getTime() >= OFFLINE_LIMIT) {
                this.removeOfflineLimitWatcher();
                tasks.forEach(
                    (task) => task.options.onOfflineLimitReach && task.options.onOfflineLimitReach(task.task),
                );
            }
        }, 1000);
    }

    private removeOfflineLimitWatcher() {
        offlineLimitWatcherInterval && clearInterval(offlineLimitWatcherInterval);
    }

    private handleReconnect = async () => {
        const socket = await this.getSocket();

        socket.on("disconnect", this.handleDisconnect);
        socket.on("reconnect", this.handleReconnect);

        tasks.forEach((task) => {
            task.options.handleReconnect && task.options.handleReconnect();
            this.subscribe(task.task, socket);
        });
        this.removeOfflineLimitWatcher();

        const results = await Promise.all(tasks.map((task) => checkStatus(task)));

        results.forEach((result) => result.isCompleted && result.task.options.handleTaskComplete(result.task.task));
    };

    private handleDisconnect = () => {
        lastDisconnectTime = new Date();
        this.setOfflineLimitWatcher();
        tasks.forEach((task) => task.options.handleDisconnect && task.options.handleDisconnect());
    };
}

const checkStatus = async (
    task: SocketTask,
): Promise<{
    task: SocketTask;
    isCompleted: boolean;
}> => {
    const { error, result } = await get<Task>(`/api/tasks/${task.task.id}`);
    if (error || !result || result.status !== "completed")
        return {
            task,
            isCompleted: false,
        };

    return {
        task: {
            ...task,
            task: result,
        },
        isCompleted: true,
    };
};

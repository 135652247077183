import { useRef } from "react";

export const useDebounce = (delay: number = 250) => {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const debounce = (fun: () => void) => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(fun, delay);
    };

    return debounce;
};

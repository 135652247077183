import axios from "axios";

const headers = {};

const axiosInstance = axios.create({
    headers: headers,
});

axiosInstance.interceptors.request.use(
    async (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

export default axiosInstance;

import { ReactNode } from "react";
import { useToolTip } from "./useToolTip";

export interface ToolTipPosition {
    y: "up" | "down";
}

export interface ToolTipProps {
    text: string;
    children: ReactNode;
    position?: ToolTipPosition;
    inactive?: boolean;
    containerClassNames?: string;
    testId?: string;
}

export const ToolTip = ({ children, text, position, containerClassNames, inactive, testId }: ToolTipProps) => {
    const { show, hide, parentRef } = useToolTip(text, position, testId);
    if (inactive) return <>{children}</>;
    return (
        <div
            className={`cursor-pointer inline-block select-none ${containerClassNames}`}
            onMouseEnter={show}
            onMouseLeave={hide}
            onClick={show}
            ref={parentRef}
        >
            {children}
        </div>
    );
};

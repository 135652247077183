type Event = "send";
type HitType = "pageview";

export const ga = (
    event: Event,
    {
        hitType,
        page,
        title,
    }: {
        hitType: HitType;
        page: string;
        title: string;
    },
) => {
    console.log(`ga event: ${event}, hitType: ${hitType}, page: ${page}, title: ${title}`);
    if (!(<any>window).ga || !(<any>window).ga.getAll) {
        const interval = setInterval(() => {
            if ((<any>window).ga && (<any>window).ga.getAll) {
                const trackerName = (<any>window).ga.getAll()[0]?.get("name") ?? "";
                (<any>window).ga(`${trackerName}.${event}`, {
                    hitType,
                    eventAction: "click",
                    page,
                    title,
                });
                clearInterval(interval);
            }
        }, 50);
    } else {
        const trackerName = (<any>window).ga.getAll()[0]?.get("name") ?? "";
        (<any>window).ga(`${trackerName}.${event}`, {
            hitType,
            page,
            title,
        });
    }
};

import { useEffect, useMemo, useRef } from "react";
import { useOnOutsideClick } from "client/hooks/utils/useOnOutsideClick";
import { useToggle } from "client/hooks/utils/useToggle";
import { useWindowSize } from "client/hooks/utils/useWindowSize";
import { ToolTipProps } from "./ToolTip";

const backgroundColor = `black`;

export const useToolTip = (
    text: ToolTipProps["text"],
    position: ToolTipProps["position"] = { y: "up" },
    testId: ToolTipProps["testId"],
) => {
    const parentRef = useRef<HTMLDivElement | null>(null);
    const containerId = useMemo(() => String.fromCharCode(65 + Math.floor(Math.random() * 26) + Date.now()), []);
    const arrowId = `${containerId}_arrow`;
    const { isOpen: active, open: show, onClose: hide } = useToggle();
    useOnOutsideClick<HTMLDivElement>(parentRef, active, hide);
    const { width } = useWindowSize();

    useEffect(() => {
        if (!parentRef.current) return;
        const container = getContainer();
        const arrow = getArrow();
        if (active) {
            const { hideArrow } = showContainer(container, parentRef.current);
            !hideArrow && showArrow(arrow, parentRef.current);
        } else {
            hideContainer(container);
            hideArrow(arrow);
        }

        return () => {
            hideContainer(getContainer());
            hideArrow(getArrow());
        };
    }, [active, text]);

    const getContainer = () => {
        const prevContainer = document.getElementById(containerId);
        if (prevContainer) {
            return prevContainer;
        }

        const container = document.createElement("div");
        container.style.zIndex = `9999`;
        container.id = containerId;
        testId && container.setAttribute("data-testid", testId);
        document.body.appendChild(container);
        return container;
    };

    const hideContainer = (container: HTMLElement) => {
        container.remove();
    };

    const showContainer = (container: HTMLElement, parent: HTMLElement) => {
        container.style.display = "inline-block";
        container.style.position = "fixed";
        const child = document.createElement("div");
        child.textContent = `${text}`;
        child.style.color = "white";
        child.style.minWidth = `100px`;
        child.style.textAlign = "center";
        container.appendChild(child);
        container.style.wordBreak = "break-all";
        container.style.backgroundColor = backgroundColor;
        container.style.padding = "1rem";
        container.style.borderRadius = `5px`;
        let { left, top, hideArrow } = getContainerPosition(container, parent);
        container.style.left = `${left}px`;
        container.style.top = `${top}px`;
        return { hideArrow };
    };

    const showArrow = (arrow: HTMLElement, parent: HTMLElement) => {
        arrow.style.backgroundColor = backgroundColor;
        arrow.style.width = `16px`;
        arrow.style.height = `16px`;
        arrow.style.display = `inline-block`;
        arrow.style.zIndex = `9999`;
        arrow.style.position = `fixed`;
        arrow.style.transform = `rotate(45deg)`;

        document.body.appendChild(arrow);

        const { left, top } = getArrowPosition(arrow, parent);
        arrow.style.left = `${left}px`;
        arrow.style.top = `${top}px`;

        return arrow;
    };

    const getArrowPosition = (arrow: HTMLElement, parent: HTMLElement): { left: number; top: number } => {
        let { left, top } = parent.getBoundingClientRect();
        top -= arrow.getBoundingClientRect().height;
        left += -8 + (parentRef.current?.getBoundingClientRect().width ?? 0) / 2;

        if (position.y === "down") {
            top = parent.getBoundingClientRect().y + parent.getBoundingClientRect().height + 5;
        }

        return {
            left,
            top,
        };
    };

    const hideArrow = (arrow: HTMLElement) => {
        arrow.remove();
    };

    const getArrow = () => {
        const prevArrow = document.getElementById(arrowId);

        if (prevArrow) {
            return prevArrow;
        }

        const arrow = document.createElement("div");
        arrow.id = arrowId;
        return arrow;
    };

    const getContainerPosition = (
        container: HTMLElement,
        parent: HTMLElement,
    ): { left: number; top: number; hideArrow: boolean } => {
        let hideArrow = false;
        let { left, top, height: parentHeight } = parent.getBoundingClientRect();
        const { width: containerWidth, height: containerHeight } = container.getBoundingClientRect();
        top -= 10 + container.getBoundingClientRect().height;
        left -= containerWidth / 2;
        left += (parentRef.current?.getBoundingClientRect().width ?? 0) / 2;

        if (window.innerWidth < containerWidth + left) {
            const diff = Math.abs(window.innerWidth - (left + containerWidth));
            left -= diff;
        }

        if (left < 0) {
            left = 0;
        }

        if (position.y === "down") {
            top = parent.getBoundingClientRect().y + parent.getBoundingClientRect().height + 10;
        }

        if (top <= 0) {
            top = parentHeight + containerHeight + top + 20;
            hideArrow = true;
        }

        return { left, top, hideArrow };
    };

    return { width, show, hide, parentRef };
};
